import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
// import Img from "react-cloudinary-lazy-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const NotasIndex = ({ data }) => {
  const Notas = data.strapi.notas.map(nota => (
    <div className="notas-card">
      <div className="notas-card-border">
        <Link to={nota.uid}>
          <h6>{nota.titulo}</h6>
        </Link>
        <Link to={nota.uid}>
          <p>{nota.descripcion}</p>
        </Link>
        <Link to={nota.uid}>
          {/* <Img
            cloudName={"lagexpress"}
            alt={nota.portada ? nota.portada.alternativeText : ""}
            imageName={
              nota.portada ? nota.portada.provider_metadata.public_id : ""
            }
            fluid={{
              maxWidth: "auto",
            }}
            style={{
              display: nota.portada ? "initial" : "none",
            }}
          /> */}
          <img
            data-sizes="auto"
            className="lazyload blur-up"
            src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/${
              nota.portada ? nota.portada.provider_metadata.public_id : ""
            }`}
            data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/${
              nota.portada ? nota.portada.provider_metadata.public_id : ""
            }`}
            alt={nota.portada ? nota.portada.alternativeText : ""}
            style={{
              display: nota.portada ? "initial" : "none",
            }}
          />
        </Link>
        <Link className="ver-nota" to={nota.uid}>
          Ver Nota Completa
        </Link>
      </div>
    </div>
  ))

  return (
    <Layout>
      <GatsbySeo
        title="Notas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Lista de notas publicadas por Eduardo Ceballos"
        canonical="https://eduardoceballos.com/notas/"
        openGraph={{
          url: "https://eduardoceballos.com/notas/",
          title: "Notas | Eduardo Ceballos",
          description: "Lista de notas publicadas por Eduardo Ceballos",
          images: [
            {
              url: `https://res.cloudinary.com/lagexpress/image/upload/v1597061925/paginas/Facebook_-_Notas_djn9ag.png`,
              alt: "Portada de la pagina eduardoceballos.com/notas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <div className="notas-container">{Notas}</div>
    </Layout>
  )
}

export const query = graphql`
  query NotasIndex {
    strapi {
      notas(sort: "createdAt:desc") {
        uid
        titulo
        descripcion
        portada {
          url
          provider_metadata
        }
      }
    }
  }
`

export default NotasIndex
